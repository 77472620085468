.loginPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}
.loginPageMobile {
  padding: 30px 0;
}
.title {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  margin: 0 0 30px;
}
.loginPageContent {
  max-height: 530px;
  width: 280px;
}
.button {
  margin: 65px 0 0;
}
.error {
  margin: 30px 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}