@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Lato:700);
.Logo_logoContainer__2UtGl {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #c252ac;
  font-size: 18px;
}
.Logo_logoContainer__2UtGl img {
  margin-right: 15px;
}
.Header_headerContainer__uvKxt {
  height: 70px;
  background: #eee;
  width: 100%;
}
.Header_headerContainerMobile__2uhLc {
  padding: 0 15px;
}
.Header_headerContainer__uvKxt.Header_isOpen__2A58z {
  position: relative;
}
.Header_headerContainer__uvKxt.Header_isOpen__2A58z:before {
  content: "";

  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.Header_contentContainer__2yLHU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.Header_rightSide__W76qj {
  flex: 1 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf {
  position: fixed;
  z-index: 22;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 70px 0 0;
  padding: 77px 35px 75px;

  list-style: none;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  overflow-y: scroll;
}
.HeaderStyles_visibleMenu__3AvXc {
  top: 0;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li {
  margin-bottom: 55px;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li > a,
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  color: #454452;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li > a:hover,
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li > div:hover,
.HeaderStyles_baseHeaderMobileMenu__3JvHf > li > a:focus {
  color: #245995;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf>.HeaderStyles_close__23R8b {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.HeaderStyles_baseHeaderMobileMenu__3JvHf .HeaderStyles_separator__rWK9a {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 52px;
}
.HeaderStyles_Button__1Mgm1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeaderStyles_ButtonLink__Q1qdO {
  font-weight: 400;
  text-decoration: underline;
}
.HeaderStyles_Button__1Mgm1 {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
  color: #29649f;
  padding: 32px 12px;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 700;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.HeaderStyles_selected__3X4kD,
.HeaderStyles_Button__1Mgm1:hover {
  color: #f58533;
}
.HeaderStyles_Button__1Mgm1:focus {
  color: #f19b2e;
}

.HeaderStyles_userMenu__1Jugy {
  display: flex;
  flex-direction: column;

  top: 50px;
  right: -10px;
  z-index: 11;
  
  width: 275px;
  
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(103,105,118, 0.19);
}
.HeaderStyles_userMenu__1Jugy .HeaderStyles_separator__rWK9a {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.HeaderStyles_userMenu__1Jugy a {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.HeaderStyles_userMenu__1Jugy a:last-child {
  margin-bottom: 0;
}
.HeaderStyles_userMenu__1Jugy a:hover,
.HeaderStyles_userMenu__1Jugy a:focus {
  color: #245995;
}
.HeaderStyles_userMenu__1Jugy>.HeaderStyles_menuContainer__3JNG5 {
  height: 100px;
}
.HeaderStyles_logoContainer__s1lWq {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 16px;
}
.HeaderStyles_photo__1WAJZ {
  height: 35px;
  width: 35px;

  border: 2px solid #0459FF;
  border-radius: 50%;

  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderStyles_userLogoContainer__1hs5a {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #245995;
  display: flex;
  justify-content: center;
  align-items: center;

}
.HeaderStyles_userLogoContainerMobile__ZYUcN {
  height: 30px;
  width: 30px; 
}
.HeaderStyles_userLogoContainer__1hs5a > span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .26px;
  color: #245995;
  text-transform: uppercase;
}
.HeaderStyles_userLogoContainer__1hs5a:hover {
  cursor: pointer;
}
.Loading_wrapper__1vh58 {
  position: relative;
  width: 100%;
  flex: 1 1;
}

.Loading_loader__21ZCa {
	height: 32px;
  width: 32px;
  display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.Loading_loader__21ZCa::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: #132e53;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-1__1X8MD 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-1__1X8MD 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-1__1X8MD {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
@keyframes Loading_loader-4-1__1X8MD {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
.Loading_loader__21ZCa span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
  -webkit-animation: Loading_loader-4-2__2WMU1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-2__2WMU1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-2__2WMU1 {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes Loading_loader-4-2__2WMU1 {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.Loading_loader__21ZCa span::before,
.Loading_loader__21ZCa span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: #132e53;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-3__1ayZt 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-3__1ayZt 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-3__1ayZt {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-3__1ayZt {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
.Loading_loader__21ZCa span::after {
  -webkit-animation: Loading_loader-4-4__2fOnl 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-4__2fOnl 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-4__2fOnl {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-4__2fOnl {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}

.Button_button__1wu1W {
  height: 46px;
  width: 100%;

  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  
  border: 1px solid transparent;
  border-radius: 10px;
  text-decoration: none;
  text-transform: uppercase;

  -webkit-transition: .3s;

  transition: .3s;
}
.Button_button__1wu1W:hover {
  cursor: pointer;
}
.Button_button__1wu1W:focus {
  outline: none;
}
.Button_button__1wu1W p:focus {
  outline: none;
  color: #f19b2e;
}
.Button_button__1wu1W>svg {
  margin: 0 10px 0 0 !important;
}

.Button_primary__1AmGn {
  color: #ffffff;
  background-color: #c252ac;
}
.Button_primary__1AmGn:hover {
  color: #fff;
  background-color: #00669f;
}
.Button_primary__1AmGn:active {
  color: #fff;
  background-color: #1D3796;
}
.Button_primary__1AmGn:disabled {
  color: #b4c6dc;
  background-color: #dfe7f2;
}

.Button_secondary__3K38_ {
  border: 1px solid #b7b3ce;
  color: #1D3796;
}
.Button_secondary__3K38_:hover {
  background-color: #FACCA7;
}
.Button_secondary__3K38_:focus {
  color: #1D3796;
}
.Button_secondary__3K38_:active {
  background: #FACCA7;
}
.Button_secondary__3K38_:disabled {
  color: #b4c6dc;
  background: #dfe7f2;
  border: 1px solid transparent;
}

.Button_link__ScZk9 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  text-transform: capitalize;
}
.Modal_modal__3F9nH {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;

  width:100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6) !important;

  display: none;
  text-align: center;
}

.Modal_modalMain__3B8dN {
  position:fixed;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);

  background: white;

  width: 100%;
  height: auto;
  width: 290px;
  max-height: 293px;

  padding: 46px 36px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Modal_modalMain__3B8dN h3 {
  margin: 0 -5px 11px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
}

.Modal_modalMain__3B8dN>div {
  width: 100%;
  flex: 1 1;
}
.Modal_modalMain__3B8dN div>button {
  max-width: 136px;
  margin: 0 auto 17px;
}

.Modal_close__YR6Zt {
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;
}
.Modal_close__YR6Zt:hover {
  cursor: pointer;
}
.Modal_show__1MepL {
  display: block;
}

.Dropdown_dropdownContainer__1N7Kl {
  position: relative;
}
.Dropdown_value__1oKKA {
  white-space: nowrap;
  color: #000;
}
.Dropdown_value__1oKKA:hover {
  cursor: pointer;
}
.Dropdown_menu__3G2GM {
  position: absolute;
  right: 0;
}
.Dropdown_menuContainer__22grk {
  position: relative;
  padding: 40px 40px 35px; 
  height: 100%;
  border-top: 3px solid #132e53;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(103, 105, 118, 0.19);
  border-radius: 2px;
}
.Dropdown_menuContainer__22grk > svg {
  position: absolute;
  top: -8px;
  left: 0;
}

.UserContainer_userContainer__1GTst {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px auto;
  flex: 1 1;
  max-width: none !important;
  max-width: initial !important;
  width: 100%;
}
.UserContainer_userContainer__1GTst >div:nth-child(2) {
  flex: 1 1;
  margin-left: 20px;
}
.UserContainer_navigation__2qc9u {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 0px;
  margin: 30px 30px 0 0;
  -webkit-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
}
.UserContainer_expanded__1zMIG {
  width: 210px;
  -webkit-transition: width .1s ease-in-out;
  transition: width .1s ease-in-out;
}
.UserContainer_expanded__1zMIG + div {
  max-width: 1230px;
}
.UserContainer_link__3LOuA {
  font-size: 18px;
  line-height: 23.4px;
  text-decoration: none;
  padding-right: 30px;
  margin-bottom: 20px;
}
.UserContainer_selected__3QLHq {
  font-weight: bold;
  color: #00669f;
}


/* Broadcasts Page */
.UserContainer_error__3D2VN {
  margin: 30px 24px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
/* ---------------- */




/* Details Broadcasts Page */
.UserContainer_backButton__1UAb7 {
  display: block;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.UserContainer_container__2swlv {
  display: inline-flex;
  width: 100%;
}
.UserContainer_container__2swlv > * {
  flex: 1 1;
}
.UserContainer_container__2swlv>button {
  max-width: 250px;
}
.UserContainer_detailsMobile__3XVS9 .UserContainer_container__2swlv {
  flex-direction: column;
  padding: 0 12px;
}
/* ---------------- */
.HomePage_homeContainer__103nx {
  display: flex;
  flex-direction: column;

  flex: 1 1;
  max-width: 798px;
  margin: auto;
}

.BaseContainer_baseContainer__1-nMv {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.LoginPage_loginPage__1vZox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}
.LoginPage_loginPageMobile__3y15c {
  padding: 30px 0;
}
.LoginPage_title__3afxs {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  margin: 0 0 30px;
}
.LoginPage_loginPageContent__VY8UX {
  max-height: 530px;
  width: 280px;
}
.LoginPage_button__EV-6m {
  margin: 65px 0 0;
}
.LoginPage_error__3Yedh {
  margin: 30px 0 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
.AccountContainer_accountContainer__1OyzD {
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  
}
html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
html, body {
  max-width: 100%;
}
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'Roboto', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  font-size: 14px;
  text-decoration: underline;
  overflow: hidden;
  color: #c252ac;
}
a:hover {
  color: #00669f;
}
a[tabIndex]:focus {
  outline: none;
}
a:hover {
  cursor: pointer;
}

a:disabled {
  color: #b4c6dc;
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
a[disabled] {
  pointer-events: none;
  color: #b4c6dc;
}
a[disabled] svg path {
  fill: #b4c6dc;
}

