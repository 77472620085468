.modal {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;

  width:100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.6) !important;

  display: none;
  text-align: center;
}

.modalMain {
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

  background: white;

  width: 100%;
  height: auto;
  width: 290px;
  max-height: 293px;

  padding: 46px 36px 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalMain h3 {
  margin: 0 -5px 11px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
}

.modalMain>div {
  width: 100%;
  flex: 1;
}
.modalMain div>button {
  max-width: 136px;
  margin: 0 auto 17px;
}

.close {
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;
}
.close:hover {
  cursor: pointer;
}
.show {
  display: block;
}
