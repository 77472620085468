.userContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px auto;
  flex: 1;
  max-width: initial !important;
  width: 100%;
}
.userContainer >div:nth-child(2) {
  flex: 1;
  margin-left: 20px;
}
.navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 0px;
  margin: 30px 30px 0 0;
  transition: width .1s ease-in-out;
}
.expanded {
  width: 210px;
  transition: width .1s ease-in-out;
}
.expanded + div {
  max-width: 1230px;
}
.link {
  font-size: 18px;
  line-height: 23.4px;
  text-decoration: none;
  padding-right: 30px;
  margin-bottom: 20px;
}
.selected {
  font-weight: bold;
  color: #00669f;
}


/* Broadcasts Page */
.error {
  margin: 30px 24px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: .56px;
  color: #f44336;
}
/* ---------------- */




/* Details Broadcasts Page */
.backButton {
  display: block;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.container {
  display: inline-flex;
  width: 100%;
}
.container > * {
  flex: 1;
}
.container>button {
  max-width: 250px;
}
.detailsMobile .container {
  flex-direction: column;
  padding: 0 12px;
}
/* ---------------- */